
.gsys {


  //技术力量
  .jsll {
    top: 300px;
    left: 200px;
    color: #717071;
    position: absolute;
    width: 450px;

    .title {
      height: 90px;
      font-size: 35px;
      line-height: 90px;
      font-weight: lighter;
    }

    .content {
      font-weight: lighter;
      letter-spacing: 3px;
      font-size: 16px;
      line-height: 24px;

    }
  }

}


.tdsl {

  .tdsl-text {
    top: 200px;
    left: 700px;
    color: #eeeeee;
    position: absolute;
    width: 450px;

    .title {
      height: 90px;
      font-size: 35px;
      line-height: 90px;
      font-weight: lighter;
    }

    .content {
      font-weight: lighter;
      letter-spacing: 3px;
      font-size: 16px;
      line-height: 24px;
      text-indent: 2em;

    }
  }

}

//产品独特性
.cpdtx {
  .cpdtx-text {
    top: 80px;
    left: 150px;
    color: #717071;
    position: absolute;
    width: 450px;
    font-weight: lighter;
    .title {
      height: 90px;
      font-size: 35px;
      line-height: 90px;
      font-weight: lighter;
    }

    .content {

      letter-spacing: 3px;
      font-size: 16px;
      line-height: 24px;

    }
  }

  .cpdtx-card {
    display: flex;
    position: absolute;
    width: 923px;
    height: 504px;
    left:221px ;
    top: 500px;
    flex-direction: row;
    box-shadow: 10px 10px 20px rgba(0,0,0,0.5);
    .left{

      color: #eeeeee;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 1px;
      text-indent: 2em;
      padding: 60px 140px 50px 40px;
      flex-direction: column;
      flex-grow: 1;
      display: flex;
      width: 50%;
      background-color: #c79735;
      position: relative;
      font-weight: lighter;
      .button{
        position: absolute;
        top: 460px;
        border: none;
        background-color: #595757;
        color: #eeeeee;
        padding: 5px 20px 5px 20px;
        outline:none;
        box-shadow: 3px 3px 10px rgba(0,0,0,0.3);
        cursor: pointer;
        transition: box-shadow 0.2s;
        &:hover{
          box-shadow:none;
        }

      }
    }

    .right{
      display: flex;
      width: 50%;

    }

  }

}


//科技创新
.kjcx {
  width: 100%;
  display: flex;
  overflow-x: hidden;

  .kjcx-img-text {
    text-shadow: 5px 2px 6px #000;
    color: #c79735;
    flex-direction: row;
    justify-content: center;
    line-height: 400px;
    font-weight: lighter;
    font-size: 60px;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    height: 740px;
    background-image: url(/img/index/5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

  }


}