.con-hg-icon {
  width: 100px;
  height: 100px;
  position: absolute;
  background-size: 100% 100%;
}


.con-ditu-container {
  width: 700px;
  height: 600px;
  position: absolute;
  left: 200px;
  top:330px;
  display: flex;
  flex-direction: column;
  color: #717071;
  align-items: center;

  .con-ditu-container-title {
    display: flex;
    justify-content: center;
    line-height: 80px;
    font-size: 35px;
    font-weight: lighter;
    width: 100%;
    height: 80px;


  }

  .con-ditu-container-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-indent: 2em;
    height: 30px;
    line-height: 30px;
    font-size: 14px;

    .con-emal{
      display: flex;
      width: 24px;
      height: 24px;
      background-image:url(/contact/con_icon5.svg) ;
      background-repeat: no-repeat;
    }
    .con-loc{
      display: flex;
      width: 24px;
      height: 24px;
      background-image:url(/contact/con_icon3.svg) ;
      background-repeat: no-repeat;
    }

    .con-phone{
      display: flex;
      width: 24px;
      height: 24px;
      background-image:url(/contact/con_icon4.svg) ;
      background-repeat: no-repeat;
    }
  }


  .con-ditu-container-tupian{
    margin-top: 30px;
    background-image: url(/contact/con2.png);
    background-size: contain;
    width: 500px;
    height: 300px;
    display: flex;
  }

}