.so-dz-bg {

  background-image: url(/solution/bg5.png);
  background-size: 400px 400px;
  background-repeat: no-repeat;
  background-position: right top;



}

.so-gy-bg {

  background-size: 400px 400px;
  background-repeat: no-repeat;
  background-position: right top;



}

.so-gy-my {


  background-color: #595757;


}

.so-dz-cy-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

}

.so-text-block {
  overflow: hidden;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: lighter;
  font-size: 36px;
  letter-spacing: 5px;

}