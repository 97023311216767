.edit-news {
  width: 100%;
  min-height: 100%;

  .edit-table {
    border-collapse: collapse;
    width: 100%;
    height: 100%;

    .title-container {
      display: flex;
      flex-direction: column;
    }

    .sec-container {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;

      .sec-container-list {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;

        .sec-container-list-item {
          border-bottom: 1px solid #503c90;
          display: flex;
          flex-direction: row;
          min-height: 100px;

          .sec-container-list-item-op {
            width: 60px;
            display: flex;
            flex-direction: column;
          }

          .sec-container-list-item-ta {
            width: 100%;
            display: flex;
            flex-direction: row;

            .sec-container-list-item-content {
              display: flex;
              width: 40%;
              padding: 10px;
            }
          }
        }
      }

      .add-sec-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        .add-sec-container-text {
          display: flex;
          flex-direction: row;

          .add-sec-item {
            width: 50%;
            display: flex;
            flex-direction: column;

            .add-sec-textarea {
              width: 90%;
            }
          }
        }
      }
    }


    .img-container {
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 100px;
      flex-direction: column;
      justify-content: flex-start;

      .op-container {
        display: flex;
        width: 100%;
        height: 30px;
      }

      .img-item-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        .img-item {
          width: 150px;
          height: 150px;
          display: flex;
          flex-direction: column;
          border: 1px solid #503c90;

          .img-content {
            display: flex;
            width: 100%;
            height: 100%;
          }

          .img-op {
            display: flex;
            height: 30px;
            flex-direction: column;
          }


        }
      }


    }

  }
}
