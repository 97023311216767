.hg5 {
  .hg5-text-zone {
    box-sizing: border-box;
    padding: 0px 50px 0px 50px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .hg5-text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      text-align: center;
    }

  }


  .zi {
    transition: color .5s, fill .5s ,stroke .5s;
    color: #503c90;
    fill: #ffffff;
    stroke: #503C90;
  }

  .lan {
    transition: color .5s, fill .5s ,stroke .5s;
    color: #2ca6e0;
    fill: #ffffff;
    stroke: #2ca6e0;
  }

  .lv {
    transition: color .5s, fill .5s ,stroke .5s;
    color: #8abb33;
    fill: #ffffff;
    stroke: #8abb33;
  }

  &:hover {
    .zi {
      color: #ffffff;
      fill: #503C90;
      stroke: #ffffff;
    }

    .lan {
      color: #ffffff;
      fill: #2ca6e0;
      stroke: #ffffff;
    }

    .lv {
      color: #ffffff;
      fill: #8abb33;
      stroke: #ffffff;
    }
  }


}