.wheel {
  width: 100%;
  height: 910px;

  background-size: 100% 910px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-around;

  transition: background-img 1s;
  position: relative;
  .wheel-svg {
    height: 100%;
    width: 100%;
  }

  .wheel-center {

    width: 1366px;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    //border: 1px solid #c30d23;
    .wheel-button-group {
      width: 50px;
      height: 40px;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .wheel-button {
        cursor: pointer;
        height: 20px;
        width: 20px;

        .wheel-button-circle {
          transition: fill 1s;
        }
      }
    }

  }


}