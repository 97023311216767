.cy-group {

  display: flex;
  flex-direction: column;
  margin: 30px;

  .cy-group-svg {
    width: 100%;
    height: 100%;
  }

  .so-cy-text-block {
    color: #717071;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: lighter;
    font-size: 14px;
  }
}

