.admin{
  width: 100%;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  .ad-table{
    width: 500px;
    height: 100px;
  }

}