
.about-hg-big {
  position: absolute;
  .about-text-zone {
    top:0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
  }
}
