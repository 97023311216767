.edit-image-container {
  border-collapse:collapse;
  font-size: 12px;
  td,th{
    border: 1px solid #707071;
  }
  .edit-img-item {
    background-size: contain;
    background-repeat: no-repeat;
    width: 400px;
    height: 300px;

  }


}