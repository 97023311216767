.edit-table{
  width: 100%;
  height: 100%;
  border-collapse:collapse;
  font-size: 12px;
  td,th{
    border: 1px solid #707071;
  }
  .content-div{
    padding: 10px;
    .content-div-ta{
      width: 100%;
      height: 100%;

    }
  }

}