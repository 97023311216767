.hr-zhaopin {
  background-image: url(/hr/hr7.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;

  .hr-zhaopin-content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;

    .hr-zhaopin-icon {
      background-image: url(/hr/hr3.svg);
      background-size: 100% 100%;
      width: 80px;
      height: 80px;
      display: flex;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .hr-zhaopin-text-zone {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #eeeeee;
      font-size: 14px;
      font-weight: lighter;

      .hr-zhaopin-text-title {
        width: 100%;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        font-weight: lighter;
      }

      .hr-zhaopin-text {
        display: flex;
        flex-direction: column;
        width: 600px;

        .hr-zhaopin-text-p {
          text-indent: 2em;
        }
      }
    }
  }
}

.hr-jiaren {
  background-color: #595757;

  .hr-jiaren-content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    .hr-jiaren-icon {
      background-image: url(/hr/hr4.svg);
      background-size: 100% 100%;
      width: 80px;
      height: 80px;
      display: flex;
      flex-shrink: 0;
      flex-grow: 0;
    }
    .hr-jiaren-title{
      color: #eeeeee;
      width: 100%;
      height: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      font-weight: lighter;
    }
  }
}