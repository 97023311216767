.signleFont {
  opacity: 0;
  display: inline-block;
}

@keyframes signleFontFrame {
  from {
    opacity: 0;
    text-shadow: 5px 2px 6px #000;
    transform: scale3d(2, 2, 2);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    text-shadow: 1px 1px 6px rgba(255,255,255,0.5);
  }
}

@keyframes hideFontFrame {
  from {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    text-shadow: none;
  }
  to {
    opacity: 0;
    text-shadow: 5px 2px 6px #000;
    transform: scale3d(2, 2, 2);
  }
}