.liucheng-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .liucheng {
    display: flex;
    background-image: url(/tech/keji.jpg);
    width: 800px;
    height: 800px;
  }
}


.tech-dashiji {

  .dashiji-icon {
    width: 75px;
    height: 75px;
    position: absolute;
    top: 0px;
    left: 645px;
    background-image: url(/tech/icon2.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .dashiji-text {
    width: 100%;
    text-align: center;
    height: 100px;
    line-height: 100px;
    font-size: 50px;
    letter-spacing: 15px;
    color: #717071;
    position: relative;
    top: 130px;
    font-weight: lighter;

  }

}

.tech-zhiliangbaozheng {
  background-image: url(/tech/banner2.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;


  .zhiliangbaozheng-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .zhiliangbaozheng-icon {
      width: 75px;
      height: 75px;
      position: absolute;
      top: 0px;
      left: 645px;
      background-image: url(/tech/icon3.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .zhiliangbaozheng-title {
      display: flex;
      justify-content: center;
      height: 140px;
      line-height: 100px;
      font-size: 50px;
      letter-spacing: 15px;
      font-weight: lighter;
      color: #eeeeee;
    }

    .zhiliangbaozheng-text {
      display: flex;
      color: #eeeeee;
      width: 500px;
      height: 100px;
      font-size: 16px;
      text-indent: 2em;
      line-height: 24px;
      font-weight: lighter;
    }
  }
}


.keyanjichu-item {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #eeeeee;

  .keyanjichu-icon {
    width: 75px;
    height: 75px;
    position: absolute;
    top: 0px;
    left: 645px;
    background-image: url(/tech/icon4.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .keyanjichu-title {
    margin-top: 100px;
    display: flex;
    font-size: 50px;
    font-weight: lighter;
    letter-spacing: 10px;
    justify-content: center;
    line-height: 100px;
    width: 100%;
    height: 100px;
  }

  .keyanjichu-content {
    margin-top: 30px;
    text-indent: 2em;
    font-size: 16px;
    font-weight: lighter;
    width: 700px;
    line-height: 24px;
    display: flex;
    justify-content: center;
  }
}


.keyanchengguo {
  background-image: url(/tech/banner3.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;


  .keyanchengguo-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .keyanchengguo-icon {
      width: 75px;
      height: 75px;
      position: absolute;
      top: 0px;
      left: 645px;
      background-image: url(/tech/icon5.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .keyanchengguo-title {
      display: flex;
      justify-content: center;
      height: 140px;
      line-height: 100px;
      font-size: 50px;
      letter-spacing: 15px;
      font-weight: lighter;
      color: #eeeeee;
    }

    .keyanchengguo-text {
      display: flex;
      color: #eeeeee;
      width: 500px;
      height: 100px;
      font-size: 16px;
      text-indent: 2em;
      line-height: 24px;
      font-weight: lighter;
    }
  }
}

.zhengshu-item {
  width: 800px;
  height: 100%;
  align-self: center;
  color: #717071;

  .zhengshu-text {
    width: 100%;
    height: 100px;
    line-height: 100px;
    font-size: 16px;
    font-weight: lighter;
    padding-left: 12px;
  }

  .zhengshu-panel {
    display: flex;
    flex-wrap: wrap;

    .zs-item {
      margin: 10px;

      display: flex;
      flex-shrink: 0;
      flex-grow: 0;
      width: 100px;
      height: 150px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

  }


}

.keyanjichu-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .keyanjichu-item{
    width: 800px;
    height: 800px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(/tech/keji.png);
  }
}