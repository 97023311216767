
.text-zone {

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  vertical-align: center;
  text-align: center;
  color: #c79735;
  font-weight: lighter;

}


.rxxcl-text-zone-top {
  //background-color: rgba(0,0,0,0.1);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  color: #c79735;
  font-size: 22px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  .rxxcl-text {
    color: #eeeeee;
    font-weight: 100;
    font-size: 38px;
    justify-content: center;
    text-align: center;
    display: flex;
    width: 200px;
    flex-shrink: 0;
    flex-grow: 0;

  }

}

