.footer {
  display: flex;
  justify-content: center;
  align-items: end;
  width: 100%;
  color: #cccccc;
  background-color: black;
  position: relative;
  z-index: 1999;

  .footer-center {
    display: flex;
    width: 1366px;
    height: 300px;

    flex-direction: row;
    justify-content: center;

    .center-left {
      font-family: 黑体;
      width: 800px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 80%;
      line-height: 30px;
      font-size: 14px;
      font-weight: lighter;
      letter-spacing: 2px
      //background-color: aquamarine;

    }

    .center-right {
      height: 80%;
      width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      //background-color: blueviolet;
      .right-icon {
        margin-right: 30px;
        height: 24px;
        width: 20px;
        display: flex;
        cursor: pointer;
        transition: transform .2s;
        &:hover{
           transform: scale(1.2);
        }
      }

      .weibo{
       background-image: url(/footer/weibo.svg);
        background-size: 100% 100%;
      }

      .weixin{
        background-image: url(/footer/微信.svg);
        background-size: 100% 100%;
      }

      .qq{
        background-image: url(/footer/qq.svg);
        background-size: 100% 100%;
      }

      .email{
        background-image: url(/footer/邮件.svg);
        background-size: 100% 100%;
      }


    }

  }

}