
.text-zone {

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  line-height: 295px;
  vertical-align: center;
  text-align: center;
  color: #c79735;
  font-size: 22px;
  font-weight: 400;

}


.text-zone-top{

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  color: #c79735;
  font-size: 22px;
  font-weight: 400;
  .font-1{
    height: 50%;
    top:0;
    left: 50px;
    text-align: center;
    line-height: 190px;

  }

  .font-2{
    height: 50%;
    top:0;
    left: 50px;
    text-align: center;
    line-height: 20px;

  }

}

