
.hr-jia-ren-hg{
  width: 800px;
  height: 600px;
  position:absolute;
  top: 180px;
  left: 300px;
  padding-left: 100px;

}
