

.wangge {
  background-image: url(/product/product-wangge.png);
  background-size: 600px 600px;
}

.p-container {
  position: absolute;
  z-index: 999;
  top: -100px;
  width: 560px;
  height: 560px;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-self: center;
  //480 540 , 240 260
  .p-item {

    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    width: 240px;
    height: 260px;
    flex-direction: column;
    border: 1px solid #c79735;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

    .p-item-pic {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;

      background-size: 100% 100%;
    }

    .p-item-title {
      font-size: 12px;
      font-weight: lighter;
      color: #eeeeee;
      height: 40px;
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      background-color: #c79735;
      align-items: center;
      justify-content: center;
    }
  }
}


.pro-text {
  align-self: center;
  width: 500px;
  display: flex;
  flex-direction: column;

  .pro-text-tilte {
    display: flex;
    height: 120px;
    line-height: 120px;
    color: #eeeeee;
    justify-content: center;
    font-size: 28px;
    font-weight: lighter;

  }

  .pro-text-tilte2 {
    color: #503c90;
    font-size: 16px;
    line-height: 20px;
    font-weight: lighter;
    margin-top: 40px;
  }

  .pro-text-content {
    text-indent: 2em;
    margin-top: 10px;
    color: #eeeeee;
    font-size: 14px;
    line-height: 20px;
    font-weight: lighter;
  }


}