$headerTransTime: 0.8s;
.header {

  z-index: 9999;
  position: fixed;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  transition: height $headerTransTime, background-color 1s ,box-shadow 2s;
  background-color: white;
  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 1366px;
    //min-width: 1366px;
    height: 100%;

    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 400px;
      height: 100%;
    }

    .link-container {

      padding: 0px 220px 0px 120px;
      height: 100%;
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .lang-group{
        margin-left: 50px;
        font-size: 14px;
        line-height: 14px;
        color: #fff;
        cursor: pointer;

        &:hover{
          color: #707071;
        }
      }

      .link-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;

        .link {
          text-decoration: none;

          &:link {
            color: #fff;
            text-decoration: none;
          }

          &:visited {
            color: #fff;
            text-decoration: none;
          }

          &:hover {
            color: #fff;
            text-decoration: none;
          }

          &:active {
            color: #fff;
            text-decoration: none;
          }
        }

        .link-small {
          &:link {
            color: #707071;
            text-decoration: none;
          }

          &:visited {
            color: #707071;
            text-decoration: none;
          }

          &:hover {
            color: #707071;
            text-decoration: none;
          }

          &:active {
            color: #707071;
            text-decoration: none;
          }
        }

        .under-line {
          height: 2px;
          background-color: #fff;
          width: 0px;
          transition: width 0.3s;
        }

        .under-line-small {
          background-color: #707071;
        }

        .under-line-hover {


        }
      }


    }
  }


}