.dashiji_png {
  display: flex;
  justify-content: center;
  margin-top: 100px;

  width: 100%;
  height: 1400px;


  .dashiji_img{
    width: 600px;
  }


}

