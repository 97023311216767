.top-zone-text{
  display: flex;
  position: absolute;
  width: 700px;
  height: 150px;
  left:150px;
  top:180px;
  color: #eeeeee;
  line-height: 30px;
  font-size: 16px;
  font-weight: lighter;


}