.wheel-hexagon {

  position: absolute;
  width: 900px;
  height: 900px;
  //border: 1px solid #ff00ff;
  top: 0px;
  left: 233px;

  .font0 {
    position: absolute;
    text-align: center;
    width: 600px;
    color: white;
    left: 150px;
    top: 400px;
    font-size: 36px;
  }

  .font1 {
    position: absolute;
    color: white;
    text-align: center;
    width: 600px;
    left: 150px;
    top: 450px;
    font-size: 40px;
    font-weight: 600;

  }


  @keyframes circle-tran {
    0% {
      transform: rotate(-90deg);
    }

    100% {
      transform: rotate(270deg);
    }
  }


  .polygon {
    fill: none;
    stroke-width: 0.5px;
    stroke: #ffffff;
  }

  .polygon-center {
    fill: #c79735;
  }

}