
.about-bg-wangge {
  background-image: url(/product/product-wangge.png);
  background-size: 600px 600px;
}

.about-hg-icon {
  width: 100px;
  height: 100px;
  position: absolute;
  background-size: 100% 100%;
}

.tan-hua-jian-jie {
  width: 500px;
  height: 400px;
  position: absolute;
  left: 200px;
  top: 240px;
  display: flex;
  flex-direction: column;
  color: #717071;

  .tan-hua-jian-jie-title {
    display: flex;
    justify-content: center;
    line-height: 80px;
    font-size: 40px;
    font-weight: lighter;

  }

  .tan-hua-jian-jie-content {
    margin-top: 30px;
    text-indent: 2em;
    line-height: 20px;
    font-size: 14px;
  }

}

.sheng-chan-ji-di{
  width: 600px;
  height: 400px;
  position: absolute;
  left: 400px;
  top: 300px;
  display: flex;
  flex-direction: column;
  color: #717071;


  .sheng-chan-ji-di-title {
    display: flex;
    justify-content: center;
    line-height: 80px;
    font-size: 40px;
    font-weight: lighter;

  }

  .sheng-chan-ji-di-content {
    margin-top: 20px;
    text-indent: 2em;
    line-height: 20px;
    font-size: 14px;
  }




}





.about-mubiao-container {
  position: absolute;
  z-index: 999;
  top: 450px;
  width: 100%;
  background-color: #717071;
  height: 300px;
  display: flex;

  .about-mubiao-item {
    height: 100%;
    width: 455px;
    display: flex;
    flex-direction: column;
    background-size: 100% 100%;
    color: #eeeeee;
    .about-mubiao-item-pic {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      position: relative;
      .about-mubiao-item-icon{
        width: 50px;
        height: 50px;
        position: absolute;
        background-size: 100% 100%;
        top: 80px;
        left: 200px;


      }
      .about-mubiao-item-title{
        color: #eeeeee;
        width: 455px;
        position: absolute;
        text-align: center;
        top: 140px;
        font-size: 30px;
        font-weight: lighter;
      }
    }

    .about-mubiao-item-text {
      justify-content: center;
      text-align: center;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      height: 50px;
      width: 100%;
      font-weight: lighter;
      font-size: 14px;
    }
  }
}

.dong-shi-zhang-color{
  background-color: #717071;
}

.dong-shi-zhang{
  width: 500px;
  height: 400px;
  position: absolute;
  left: 275px;
  top: 300px;
  display: flex;
  flex-direction: column;
  color: #717071;


  .dong-shi-zhang-title {
    display: flex;
    justify-content: center;
    line-height: 80px;
    font-size: 40px;
    font-weight: lighter;

  }

  .dong-shi-zhang-content {
    margin-top: 20px;
    text-indent: 2em;
    line-height: 20px;
    font-size: 14px;
  }

}