

$transTime:0.8s;
$transTime1:0.8s;
.cls-2 {
  fill: #c79735;
  transition:fill $transTime;
}

.cls-3 {
  fill: #595757;
  transition:fill $transTime;
}

.cls-4 {
  fill: #c30d23;
  transition:fill $transTime;
}

.cls-white{
  fill: #ffffff;
}

.logo {
  width: 200px;
  transition:transform $transTime1;
}
