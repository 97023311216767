
.page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  position: relative;
  overflow: visible;
  .page-wrap {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
    align-items: center;
    overflow: visible;
    .page-center {
      overflow: visible;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 1366px;
      height: 100%;
      position: relative;
    }
  }

  .page-wrap-color-yellow {
    background-color: #c79735;
  }

  .page-wrap-color-white {
    background-color: #ffffff;
  }

  .svg-style {
    fill: blue;
    stroke: pink;
    stroke-width: 5;
    fill-opacity: 0.1;
    stroke-opacity: 0.9;
  }
}

.product-top-img {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;


  .top-icon-svg {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 150px;
    left: 1050px;
  }
}