.new-kt-bg {
  background-image: url(/news/bg51.png);
  background-size: 400px 400px;
  background-repeat: no-repeat;
  background-position: right top;

  .so-dz-cy-container {
    width: 100%;
    height: 100%;
    border: 1px solid #c30d23;
    display: flex;
    justify-content: center;
    align-items: flex-end;

  }

}


.news-panel-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .news-top-item {
    height: 355px;
    width: 355px;
    display: flex;
    flex-direction: column;
    background-size: 100% 100%;
    color: #eeeeee;

    .news-top-item-pic {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      position: relative;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .news-top-item-svg {
        position: absolute;
      }

      .news-top-item-title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #eeeeee;
        width: 80px;
        text-align: center;
        top: 140px;
        font-size: 30px;
        font-weight: lighter;
      }
    }
  }


  .news-card {
    display: flex;
    flex-direction: column;
    width: 1065px;
    height: 250px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: box-shadow 0.2s;

    &:hover {
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    }

    .lanmu {
      align-items: center;
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      height: 40px;
      width: 100%;
      color: #707071;
      font-size: 14px;
      padding-left: 5px;
    }

    .n-content {
      width: 100%;
      height: 100%;
      display: flex;


      .c-left {
        box-sizing: border-box;
        display: flex;
        height: 100%;
        width: 70%;
        flex-direction: column;

        padding: 5px 80px 10px 30px;

        .c-left-top {
          display: flex;
          width: 100%;
          height: 60px;
          align-items: center;

          .c-left-top-span {
            color: #eeeeee;
            background-color: #c79735;
            border-radius: 15px;
            padding: 3px 20px 3px 20px;
          }
        }

        .c-left-bottom {
          display: flex;
          width: 100%;
          height: 100%;
          text-indent: 2em;
          font-size: 14px;
          color: #707071;

        }
      }

      .c-right {
        display: flex;
        height: 100%;
        width: 30%;

        .c-right-img {
          width: 85%;
          height: 85%;
        }
      }
    }
  }
}

.new-detail-container {

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #707071;

  .new-detail-title {
    box-sizing: border-box;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: lighter;
    line-height: 200px;

  }

  .news-detail-sec {
    margin-top: 40px;
    display: flex;
    width: 600px;
    text-indent: 2em;
    font-size: 14px;
    line-height: 20px;
  }

  .news-detail-image-container {
    margin-top: 40px;
    display: flex;
    width: 600px;
    flex-wrap: wrap;

    .news-detail-image-item {
      width: 290px;
      height: 190px;
      display: flex;
      margin: 5px;

      .news-detail-image {
        width: 100%;
        height: 100%;
      }


    }
  }
}